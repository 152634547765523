import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { first} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CountdownTimerService } from 'ngx-timer';
import { countUpTimerConfigModel, timerTexts } from 'ngx-timer';
import { AlertService, AuthenticationService, generalRequestsService } from '../../_services/index';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalWindow } from '@ng-bootstrap/ng-bootstrap/modal/modal-window';
// import { party } from "node_modules/party-js/bundle/party.js";

@Component({
    selector: 'app-loginmodel',
    templateUrl: './loginmodel.component.html',
    styleUrls: ['./loginmodel.component.scss']
})
export class LoginmodelComponent implements OnInit {
    old: boolean = true;
    myControl = new UntypedFormControl();
    CountryControl = new UntypedFormControl();
    filteredCountry: Observable<any[]>;
    StateControl = new UntypedFormControl();
    filteredState: Observable<any[]>;
    UtilityControl = new UntypedFormControl();
    filteredUtility: Observable<any[]>;
    CircleControl = new UntypedFormControl();
    filteredCircle: Observable<any[]>;
    DivisionControl = new UntypedFormControl();
    filteredDivision: Observable<any[]>;
    SubDivisionControl = new UntypedFormControl();
    filteredSubDivision: Observable<any[]>;
    LoginSubDivisionControl = new UntypedFormControl();
    filteredLoginSubDivision: Observable<any[]>;
    filteredOptions: Observable<string[]>;
    loginFormAuth: UntypedFormGroup;
    loginForm: UntypedFormGroup;
    mpinLoginForm: UntypedFormGroup;
    otpValidateForm: UntypedFormGroup;

    isLoginFormShowing = true;
    isAuthLoginFormShowing = true;
    isOtpLoginFormShowing = false;
    enableForgotString = false;

    loading = false;
    submittedAuth = false;

    showHideLoginWithOtp = true;
    showHideLoginWithPassword = true;
    showHideLogin = false;
    showHidePasswordField = false;

    submitted = false;
    submittedOtp = false;
    submittedOtpLogin = false;
    loadingOtpLogin = false;
    loadingOtpResend = false;
    isloginForm = false;
    isMpinForm = false;
    isShowOtpForm = false;
    isMpinFormValidate = false;
    resendOtp = true;
    resendOtpNew = true;
    resendOtpButton = false;
    loadingLogin = false;
    loadingAuthLogin = false;
    loadingOtp = false;
    returnUrl: string;
    responseData: any;
    ResponseMessage: string;
    token: string;
    invalidMessage: string;
    subDevision = [{
        'sub_dev_id': 1,
        'sub_dev_name': 'Select your subdevision',
        'dev_id': 1,
        'circle_id': 1,
        'utility_id': 1,
        'state_id': 1,
        'country_id': 1,
        'auth_id': 1,
        'prefix': 1,
        'tb': 'table',
        'subDevisionDatabaseName': ''
    }];

    isloadedSubDivision = false;
    isOtploadedSubDivisionSelected = false;
    selectedCountry = 1;
    selectedState = 1;
    selectedUtility = 1;
    selectedCircle = 1;
    selectedDevision = 1;
    selectedSubDevision = 0;
    selectedSubDevisionName = '';
    selectedSubDevisionDbName = '';
    options = [{ id: 34, description: 'Adding new item' }, { id: 35, description: 'Adding new item1' }, { id: 36, description: 'Adding new item3' }];
    optionsCountry: any[] = [{ id: 0, name: 'Please select' }];
    testConfig: any;
    enteredmobileNo = '';
    enteredemailId = '';
    rembermeAccess = {
        username: '',
        password: '',
        subdevid: ''
    }
    isLoginSubDivChecked = false;
    //count down counter
    message1 = 'Forgot your Password?';
    message2 = "Don't worry, we ll send you message to reset your password";
    message3 = 'OTP Login?';
    message4 = "Please provide username received at the time of registration.";
    headingOne = "Forgot Password";
    headingTwo = "Otp Login";
    imageAddress1 = "assets/img/forgot-pssword.png";
    imageAddress2 = "assets/img/otp_img.png";

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private genReqs: generalRequestsService,
        private alertService: AlertService,
        public CountdownTimerService: CountdownTimerService,
        private modalService: NgbModal,
        public modal: NgbActiveModal,
    ) {

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            //  console.log("in login");
            let deshboard = "/" + this.authenticationService.getDeshboardRoute();
            this.router.navigate([deshboard]);
        }
        this.rembermeAccess = this.authenticationService.getRemberMeAccess();
    }

    ngOnInit() {
        this.getSubDomainLists();
        let cdate = new Date();
        cdate.setMinutes(cdate.getMinutes() + 1);
        this.CountdownTimerService.startTimer(cdate);
        this.testConfig = new countUpTimerConfigModel();

        //custom class
        this.testConfig.timerClass = 'test_Timer_class';

        //timer text values  
        this.testConfig.timerTexts = new timerTexts();
        this.testConfig.timerTexts.hourText = "";
        this.testConfig.timerTexts.minuteText = ":";
        this.testConfig.timerTexts.secondsText = "Sec";
        this.CountdownTimerService.onTimerStatusChange.subscribe(status => {
            if (status == 'STOP') {
                this.enabledResendOtp();
            }
        });
        this.CountdownTimerService.stopTimer;
        this.loginFormAuth = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remberMyLogin: ['']
        });
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            subDevision: [''],
            remberMyLogin: ['']
        });

        if (this.rembermeAccess.password != '' && this.rembermeAccess.username != '') {
            this.loginForm.controls['username'].setValue(this.rembermeAccess.username);
            this.loginFormAuth.controls['username'].setValue(this.rembermeAccess.username);
            this.loginForm.controls['password'].setValue(this.rembermeAccess.password);
            this.loginFormAuth.controls['password'].setValue(this.rembermeAccess.password);
        }

        this.mpinLoginForm = this.formBuilder.group({
            mobileNo: ['', Validators.required],
            //emailId: ['', Validators.required],
            // email: new FormControl('', Validators.compose([
            //   Validators.required,
            //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            // ])),
            subDevision: ['']
        });

        this.otpValidateForm = this.formBuilder.group({
            otp: ['', Validators.required],
        });
        
        //console.log("agent",this.isMobile());
        // get return url from route parameters or default to '/'
        //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        //this.router.navigate(["login"]);
        //this.router.reload()
        location.reload();
    }

    // convenience getter for easy access to form fields
    get af() { return this.loginFormAuth.controls; }
    get f() { return this.loginForm.controls; }
    get m() { return this.mpinLoginForm.controls; }
    get t() { return this.otpValidateForm.controls; }

    // specialofferbtn() {
    //     party.confetti({
    //         count: party.variation.range(20, 40),
    //     });

    // }

    /*isMobile(){
        // credit to Timothy Huang for this regex test: 
        // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        {
            return true;
        }
        else
        {
            return false;
        }
    } */

    onSubmitAuth() {
        this.invalidMessage = '';
        this.submittedAuth = false;
        this.isShowOtpForm = false;
        if (!this.submittedAuth) {
            this.submittedAuth = true;
            // stop here if form is invalid
            if (this.loginFormAuth.invalid) {
                return;
            }
            this.loadingAuthLogin = true;
            let username = this.af.username.value;
            let password = this.af.password.value;
            let subdevid = '';
            let subdevDb = '';
            let remberme = this.af.remberMyLogin.value || false;
            this.authenticationService.login(username, password, subdevid, subdevDb, remberme, this.token)
                .pipe(first()).subscribe(
                    data => {
                        this.loadingAuthLogin = false;
                        if (data.success) {
                            //  console.log("root url");
                            let deshboard = "/" + this.authenticationService.getDeshboardRoute();

                            this.router.navigate([deshboard]);
                            console.log("inner width",window.innerWidth);
                            if (window.innerWidth <= 550) {
                                console.log("In True condition");
                                //location.reload();
                                window.location.href = deshboard;
                            }
                            this.submittedAuth = false;
                            this.modal.close();
                        } else {
                            this.loginForm.controls['username'].setValue('');
                            this.loginForm.controls['password'].setValue('');
                            this.LoginSubDivisionControl.setValue(0);
                            this.SubDivisionControl.setValue(0);
                            this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                            this.isShowOtpForm = true;
                            this.submittedAuth = false;
                            this.loading = false;
                        }
                    },
                    error => {
                        this.loadingAuthLogin = false;
                        this.invalidMessage = '<div class="errors shakeMsg">Username Or password is incorrect, please try again!</div>';
                        this.isShowOtpForm = true;
                        this.submittedAuth = false;
                        this.loading = false;
                        this.isShowOtpForm = true;
                    });
        }
    }

    enableForgotLogin() {
        this.m.mobileNo.setValue('');
        this.invalidMessage = '';
        this.isloginForm = false;
        this.isMpinForm = true;
        this.isLoginFormShowing = false;
        this.isOtpLoginFormShowing = true;
        this.isloadedSubDivision = false;
        this.isAuthLoginFormShowing = false;
        this.enableForgotString = true;
    }

    enableOtpLogin() {
        this.m.mobileNo.setValue('');
        this.invalidMessage = '';
        this.isloginForm = false;
        this.isMpinForm = true;
        this.isLoginFormShowing = false;
        this.isOtpLoginFormShowing = true;
        this.isloadedSubDivision = false;
        this.isAuthLoginFormShowing = false;
        this.enableForgotString = false;
    }

    enablePasswordField() {
        this.showHideLoginWithOtp = false;
        this.showHidePasswordField = false;
    }

    onSubmitMpin() {

        if (!this.submittedOtp) {
            this.submittedOtp = true;
            this.invalidMessage = '';

            if (this.mpinLoginForm.invalid) {
                return;
            }

            this.loadingOtp = true;
            let mobileNo = this.m.mobileNo.value;

            this.enteredmobileNo = mobileNo;
            this.resendOtpButton = false;
            let cdate = new Date();
            cdate.setMinutes(cdate.getMinutes() + 1);
            this.CountdownTimerService.startTimer(cdate);
            //return false;
            /** ###To Do remove after test */
            this.authenticationService.mpinLogin(mobileNo, this.token)
                .subscribe(
                    data => {
                        this.loadingOtp = false;
                        this.submittedOtp = false;
                        if (data.success) {
                            this.invalidMessage = data.message;
                            if (this.invalidMessage.includes("We are not able to process")) {
                                this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                                this.resendOtpNew = true;
                                this.resendOtpButton = true;
                                this.resendOtp = false;
                            } else {
                                this.resendOtp = true;
                                let cdate = new Date();
                                cdate.setMinutes(cdate.getMinutes() + 1);
                                this.CountdownTimerService.startTimer(cdate);
                                this.resendOtpNew = false;
                            }
                            this.isMpinForm = false;
                            this.isMpinFormValidate = true;
                            this.isOtpLoginFormShowing = false;
                            this.isloadedSubDivision = false;

                        } else {
                            this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                        }
                    },
                    error => {
                        this.loadingOtp = false;
                        this.submittedOtp = false;
                        this.invalidMessage = '<div class="errors">Failed to generate OTP, Invalid Username</div>';
                    });
        }
    }

    onSubmitOtpValidate() {
        this.submittedOtpLogin = false;
        if (!this.submittedOtpLogin) {
            this.submittedOtpLogin = true;
            if (this.otpValidateForm.invalid) {
                return;
            }
            this.loadingOtpLogin = true;
            let mobileNo = this.m.mobileNo.value;
            //console.log("-->", mobileNo);
            //let emailId = this.m.emailId.value;
            let otp = this.t.otp.value;
            let subdevid = this.selectedSubDevision.toString();//this.m.subDevision.value;
            // this.loading = true;
            //this.authenticationService.mpinOtpValidate(mobileNo, emailId, otp, subdevid, this.token)
            this.authenticationService.mpinOtpValidate(mobileNo, otp, this.token, this.enableForgotString)
                .subscribe(
                    data => {
                        this.loadingOtpLogin = false;
                        if (data.success) {
                            this.isMpinForm = false;
                            this.isMpinFormValidate = true;
                            this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                            let deshboard = "/" + this.authenticationService.getDeshboardRoute();
                            this.router.navigate([deshboard]);
                            this.submitted = false;
                            this.modal.close();
                        } else {
                            this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                        }
                    },
                    error => {
                        this.loadingOtpLogin = false;
                        this.invalidMessage = 'invalid Otp!';
                    });
        }
    }

    enabledResendOtp() {
        this.resendOtpButton = true;
    }

    shakeffect() {
        $(document).ready(function () {
            $('.log-btn').click(function () {
                $('.log-status').addClass('wrong-entry');
                $('.alert').fadeIn(500);
                setTimeout("$('.alert').fadeOut(1500);", 3000);
            });
            $('.form-control').keypress(function () {
                $('.log-status').removeClass('wrong-entry');
            });

        });
    }
    reGenrateOtp() {
        if (this.resendOtp) {
            this.loadingOtpResend = true;
            let subdevid = this.selectedSubDevision.toString();
            let subdevDb = this.selectedSubDevisionDbName.toString();
            // this.loading = true;
            //this.authenticationService.mpinLogin(this.enteredmobileNo, this.enteredemailId, subdevid, subdevDb, this.token)
            this.authenticationService.mpinLogin(this.enteredmobileNo, this.token)
                .subscribe(
                    data => {
                        /*this.isMpinForm = false;
                        this.isMpinFormValidate = true;
                        this.invalidMessage = '<div style="color: green;">' + data.message + '</div>';
                        this.resendOtpButton = false;
                        this.resendOtp = true;
                        this.loadingOtpResend = false;
                        let cdate = new Date();
                        cdate.setMinutes(cdate.getMinutes() + 1);
                        this.CountdownTimerService.startTimer(cdate);*/

                        this.invalidMessage = data.message;
                        if (this.invalidMessage.includes("We are not able to process")) {
                            this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                            this.resendOtpNew = true;
                            this.resendOtpButton = true;
                            this.resendOtp = false;
                        } else {
                            this.resendOtp = true;
                            let cdate = new Date();
                            cdate.setMinutes(cdate.getMinutes() + 1);
                            this.CountdownTimerService.startTimer(cdate);
                            this.resendOtpNew = false;
                        }
                        this.isMpinForm = false;
                        this.isMpinFormValidate = true;
                        this.isOtpLoginFormShowing = false;
                        this.isloadedSubDivision = false;

                    },
                    error => {
                        this.resendOtpButton = false;
                        this.invalidMessage = '<div class="errors">Failed to generate OTP, Invalid Username</div>';
                    });
        }
    }

    reGenrateOtpNew() {
        if (this.resendOtpNew) {
            this.loadingOtpResend = true;
            let subdevid = this.selectedSubDevision.toString();
            let subdevDb = this.selectedSubDevisionDbName.toString();
            // this.loading = true;
            //this.authenticationService.mpinLogin(this.enteredmobileNo, this.enteredemailId, subdevid, subdevDb, this.token)
            this.authenticationService.mpinLogin(this.enteredmobileNo, this.token)
                .subscribe(
                    data => {
                        /*this.isMpinForm = false;
                        this.isMpinFormValidate = true;
                        this.invalidMessage = '<div style="color: green;">' + data.message + '</div>';
                        this.resendOtpButton = false;
                        this.resendOtp = true;
                        this.loadingOtpResend = false;
                        let cdate = new Date();
                        cdate.setMinutes(cdate.getMinutes() + 1);
                        this.CountdownTimerService.startTimer(cdate);*/

                        this.invalidMessage = data.message;
                        if (this.invalidMessage.includes("We are not able to process")) {
                            this.invalidMessage = '<div class="errors">' + data.message + '</div>';
                            this.resendOtpNew = true;
                            this.resendOtpButton = true;
                            this.resendOtp = false;
                        } else {
                            this.resendOtp = true;
                            let cdate = new Date();
                            cdate.setMinutes(cdate.getMinutes() + 1);
                            this.CountdownTimerService.startTimer(cdate);
                            this.resendOtpNew = false;
                        }
                        this.isMpinForm = false;
                        this.isMpinFormValidate = true;
                        this.isOtpLoginFormShowing = false;
                        this.isloadedSubDivision = false;

                    },
                    error => {
                        this.resendOtpButton = false;
                        this.invalidMessage = '<div class="errors">Failed to generate OTP, Invalid Username</div>';
                    });
        }
    }

    applySubDomainOtp() {
        this.isMpinForm = true;
    }

    //function for enable and disable login sub division
    enableLoginPwdSubDiv(event) {
        if (event.target.checked) {
            this.isloginForm = true;
            this.isAuthLoginFormShowing = false;
            this.loginForm.controls['username'].setValue(this.af.username.value);
            this.loginForm.controls['password'].setValue(this.af.password.value);
        } else {
            this.isloginForm = false;
            this.isAuthLoginFormShowing = true;
        }
        this.invalidMessage = '';
        this.submittedAuth = false;
        this.submitted = false;
        this.submittedOtp = false;
    }

    getSubDomainLists() {
        localStorage.setItem('accessAuth', "eyJ0eXBlIjoiQVZPTlNNIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJyZW1vdGUiOiJOa0ZIUzNRNGRHTnlOREJHVTFZclQwcHVUamxqUVQwOSIsInVzZXJUeXBlIjoiZWtacmVWaGFPRmhCZDBNMVNsTnlkRGNyWWpneFVUMDkiLCJkYXRldGltZSI6IjIwMjMtMTAtMjcgMTE6MjU6NTIifQ.Yil6bWz9NYKOGhgyxIemo5eQWVvyZdDvmVQtilZ76LY");

        /*this.loading = true;
        this.genReqs.genReq('/getsubdevisions').then((result) => {
            this.responseData = result;
            if (this.responseData.success) {
                this.subDevision = this.responseData.data;
                this.token = this.responseData.authToken;
                localStorage.setItem('accessAuth', this.token);
            } else {
                this.ResponseMessage = this.responseData.message;;//this.responseData.isresponse;
            }
        }, (err) => {
            this.ResponseMessage = 'Unable to process yours request!';
        });*/
    }

    enableMpinForm() {
        this.invalidMessage = '';
        this.isMpinForm = true;
        this.isOtpLoginFormShowing = true;
        this.isMpinFormValidate = false;
        this.isloadedSubDivision = false;
        this.loadingOtp = false;
        this.submittedOtp = false;
        this.resendOtpButton = false;
        //this.CountdownTimerService.stopTimer();
    }

    enableLoginForm() {
        this.af.username.setValue('');
        this.af.password.setValue('');
        this.invalidMessage = '';
        this.isloginForm = true;
        this.isMpinForm = false;
        this.isLoginFormShowing = true;
        this.isOtpLoginFormShowing = false;
        this.isloadedSubDivision = true;
        this.isAuthLoginFormShowing = true;
    }

}
