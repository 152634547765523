export const webconfig = {
    //apiUrlTestLocal : 'http://sds.smesystems.in',
    //siteUrl : 'http://avonmeters.in',
    //apiUrl: 'http://api.avonmeters.in',
    apiUrlx : 'http://apiavonsm.local',
    siteUrl: 'https://myavonlive.com',
    // storageKey : 'jL9g3~U?A3uksMF',
    //apiUrl : 'http://api.avonsmartsolutions.xyz',;
    apiUrl: 'https://api.myavonlive.com',
    storageKey : 'jL9g3~U?A3uksMF',
}